import { InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";

export interface PerformanceCurve {
    curveType: PerformanceCurveType;
    points: Point[];
    unitType: UnitType;
    inputSignalLevel: InputSignalLevel;
    actualTargetCurveType: ActualTargetCurveTye;
    couplerType: CouplerType;
    graphViewType: GraphViewType;
}

type InputSignalLevel = "Undefined" | "Soft" | "Normal" | "Loud";
type ActualTargetCurveTye = "Undefined" | "Actual" | "Target";

export enum GraphViewType {
    outputVsFrequency = "OutputVsFrequency",
    insertionGainVsFrequency = "InsertionGainVsFrequency",
}

export enum UnitType {
    dB = "dB",
    dBSPL = "dBSPL",
    dBHL = "dBHL",
}

type CouplerType = "InSitu";

export type PerformanceCurveType =
    | "ActualMaximumPowerOutput"
    | "ActualSoft"
    | "ActualNormal"
    | "ActualLoud"
    | "TargetSoft"
    | "TargetNormal"
    | "TargetLoud"
    | "HearingAidLimit"
    | "GainLimit"
    | "EstimatedFeedbackThreshold"
    | "MeasuredFeedbackThreshold";

export interface Point {
    frequency: number;
    value: number;
}

const useGetPerformanceGraphQuery = <TSelector = PerformanceCurve[]>(
    hearingAidId: string | undefined,
    programId: string | undefined,
    options: {
        select?: (data: PerformanceCurve[]) => TSelector;
        enabled?: boolean;
        notifyOnChangeProps?: Array<keyof InfiniteQueryObserverResult>;
    } = { enabled: true }
) => {
    return useBaseQuery<PerformanceCurve[], TSelector>(
        {
            url: `PerformanceGraphs/GetGraph?programId=${programId}&hearingAidId=${hearingAidId}`,
            boundedContext: "PerformanceGraphs",
            notifyOnChangeOn:
                !hearingAidId || !programId ? undefined : `${hearingAidId}|${programId}`,
        },
        {
            enabled: !!hearingAidId && !!programId && options.enabled,
            select: options.select,
            notifyOnChangeProps: options.notifyOnChangeProps,
        }
    );
};

export { useGetPerformanceGraphQuery };
